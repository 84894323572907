import useSWR from "swr";
import fetcher from "utils/fetcher";

const useFetcher = (key, options = {}) => {
  const {
    isContinuous = true
  } = options;

  const swrOptions = {};

  if(!isContinuous) {
    swrOptions.revalidateOnFocus = false;
    swrOptions.revalidateOnReconnect = false;
  }

  const data = useSWR(key, fetcher, swrOptions);
  return data;
};

export default useFetcher;
